<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {

      let user_id = localStorage.getItem('user_id');
      if(user_id){
        this.showElementById('jsd-widget')
      }else{
        this.hideElementById('jsd-widget')
      }
      // clear alert on location change
      this.clearNotification();
    },
  },
  mounted() {
    // Create the script element
    if(process.env.VUE_APP_SHOW_SUPPORT_FLOAT_BTN){

      const script = document.createElement("script");
      script.setAttribute("data-jsd-embedded", "");
      script.setAttribute("data-key", "ef57273e-44cc-45f9-8a9c-a1ee52bc884b");
      script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
      script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
      script.async = true;

      // Append the script after the app div
      const appDiv = document.getElementById("app");
      if (appDiv) {
        appDiv.parentNode.insertBefore(script, appDiv.nextSibling);
      }
    }

  },
};
</script>
